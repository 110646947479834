
import { defineComponent, inject, ref, Ref } from "vue";
import { RespBody } from "@/apis/interface/Resp";
import {
  ICompany,
  MoreCompnayInfoModel,
  CompanyModel
} from "@/apis/interface/ICompany";
import { ElMessage } from "element-plus";
import { getIndustryTree } from "@/utils/enum-res";
import { IndustryEnumModel } from "@/apis/interface/IEnum";

export default defineComponent({
  setup() {
    const apiCompany = inject("apiCompany") as ICompany;
    let dialogVisible = ref(false);
    let companyId = ref(0);

    let moreInfo: Ref<MoreCompnayInfoModel | null> = ref(null);

    let industryList: Ref<Array<IndustryEnumModel> | []> = ref([]);

    let setData = async (row: CompanyModel) => {
      companyId.value = row.id || 0;
      const ret = await apiCompany.get(companyId.value);
      moreInfo.value = ret.object.detailData || new MoreCompnayInfoModel({});
      await initTree();
      dialogVisible.value = true;
    };

    let updateDetaiData = async () => {
      let companyModel = new CompanyModel({});
      companyModel.id = companyId.value;
      companyModel.detailData = moreInfo.value || new MoreCompnayInfoModel({});
      let result = (await apiCompany.set(companyModel)) as RespBody<boolean>;
      if (result.object) {
        ElMessage({
          type: "success",
          message: "修改更多信息成功"
        });
      } else {
        ElMessage({
          type: "error",
          message: "修改更多信息失败"
        });
      }
      dialogVisible.value = false;
    };

    let initTree = async () => {
      industryList.value = getIndustryTree();
      console.log(industryList.value);
    };

    return {
      dialogVisible,
      moreInfo,
      industryList,
      setData,
      updateDetaiData
    };
  }
});
